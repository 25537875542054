/*html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    min-height: 100%;
}

header.main-header {
    height: 150px;
    background-color: brown;
}

.main-content {
    flex: 1;
    -ms-flex: 1;
    margin-left: auto;
    margin-right : auto;
    width: 50%;
}

footer.main-footer {
    height: 150px;
    background-color: brown;
    margin-bottom: auto;
}*/
